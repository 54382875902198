import { GetServerSideProps } from "next";

import Head from "next/head";

import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { NavigationBarHeader, NewCampaignModal, SourcingCampaignsPage } from "@components";
import { useApp } from "@contexts";
import { loadTranslations } from "@lib";
import { NextApplicationPage } from "@typings";

const SourcingCampaignList: NextApplicationPage = () => {
  const { t } = useTranslation(["header", "campaign-list"]);
  loadTranslations("campaign-list");
  loadTranslations("header");

  const { campaignCreated, setCampaignCreated } = useApp();

  return (
    <>
      <Head>
        <title>{t("start_sourcing_head")}</title>
      </Head>

      <NavigationBarHeader pathName="/" />
      <SourcingCampaignsPage />
      <NewCampaignModal open={campaignCreated} setOpen={setCampaignCreated} />
    </>
  );
};

export default SourcingCampaignList;
SourcingCampaignList.auth = true;

export const getServerSideProps: GetServerSideProps = async ({ locale }) => ({
  props: await serverSideTranslations(locale ?? "fr", ["header", "campaign-list"]),
});
